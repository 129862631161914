/* eslint-disable react/prop-types */

import React from "react";
import { PropTypes } from "prop-types";
import { graphql, Link } from "gatsby";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const ArtistsPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const artists = data.allMarkdownRemark.edges.map(({ node }) => node);

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="artists-page w-full relative">
        <section className="artists-page__list w-full relative py-48 sm:pt-32 sm:pb-48 bg-slate text-off-white">
          <article className="grid">
            <h3 className="grid-end-6 md:grid-end-8 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 mb-6 b2 uppercase">
              Submissions
            </h3>

            <ul className="artists-page__links grid-end-6 md:grid-end-8 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 relative">
              {artists.map((artist, index) => {
                const { slug } = artist.fields;

                return (
                  <li
                    key={slug}
                    className="animation-appear relative pb-2 sm:pt-1 sm:pb-1 border-b-white"
                    style={{ animationDelay: `${index * 40}ms` }}
                  >
                    <Link
                      to={slug}
                      className="artists-page__link transition-opacity flex xs:flex-col items-end xs:items-start justify-between sm:py-1"
                    >
                      <h2 className="f1">{artist.frontmatter.title}</h2>
                      <h2 className="mb-1 b2 uppercase">
                        {artist.frontmatter.role}
                      </h2>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </article>
        </section>
      </Layout>

      <Footer />
    </>
  );
};

ArtistsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({})
    })
  }).isRequired,
  location: PropTypes.shape({}).isRequired
};

export default ArtistsPage;

export const query = graphql`
  query ArtistsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "artist-page" } } }
      sort: { fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            role
          }
        }
      }
    }
  }
`;
